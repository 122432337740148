.contacts {
	padding: 100px 0px 0px 0px;
	background: url("../images/contacts-bg.svg") center top/cover no-repeat;
	color: #ffffff;
	&__body {
		min-height: 770px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: column;
	}

	&__title {
		padding: 0px 0px 20px 0px;
	}

	&__text {
		font-size: 17px;
		line-height: 145%;
		text-align: center;
		margin: 0px 0px 50px 0px;
		p{
			margin: 0px 0px 6px 0px;
			a{
				font-weight: 700;
			}
		}
		@media (max-width: 767px) {
			margin: 0px 0px 30px 0px;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0px 0px 30px 0px;
		&._sending{
			.spinner{
				opacity: 1;
				visibility: visible;
				@media (max-width:425px) {
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
			.contacts__button{
				span{
					opacity: 0;
					visibility: hidden;
				}
				@media (max-width:425px) {
					background: #6978df;
				}
			}
		}
	}

	&__inputs {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0px 0px 60px 0px;
		@media (max-width: 767px) {
			flex-direction: column;
			margin: 0px 0px 30px 0px;
		}
	}

	&__placeholder{
		font-family: "Gilroy", sans-serif;
		font-weight: bold;
		font-size: 14px;
		line-height: 145%;
		text-align: center;
		color: #ffffff;
		text-align: left;
		margin: 0 25px;
		flex: 0 0 360px;
		position: relative;
		span{
			position: absolute;
			top: 14px;
			left: 50px;
			pointer-events: none;
			transition: all 0.2s ease 0s;
			&.show{
				transform: translate(200px, 0);
				opacity: 0;
				visibility: hidden;
			}
		}
		@media (max-width: 992px) {
			flex: 0 0 300px;
		}
		@media (max-width: 767px) {
			flex: none;
			margin: 10px 0;
		}
	}

	&__input {
		width: 100%;
		font-size: 16px;
		background: transparent;
		color: #ffffff;
		border-bottom: 2px solid #fff;
		padding: 14px 14px 14px 50px;
		background: url("../images/footer-client.webp") 10px center no-repeat;
		&[type="tel"] {
			background: url("../images/footer-phone.webp") 10px center no-repeat;
		}
	}

	&__button {
		background: #5e6edd;
		box-shadow: 0px 10px 40px rgba(69, 74, 166, 0.5);
		border: 1px solid transparent;
		transition: all 0.8s ease 0s;
		&:hover {
			background: #6e81ff;
			background-position-x: 100%;
			color: #fff;
			box-shadow: 0px 10px 300px rgba(69, 74, 166, 0.5);
		}
	}

	&__policy {
		color: #53555d;
		text-align: center;
		max-width: 550px;
		font-size: 13px;
		line-height: 145%;
		margin: 0px 0px 20px 0px;
		a {
			color: #5e6edd;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__phone {
		font-size: 22px;
		line-height: 130%;
		color: #ffffff;
		transition: all 0.3s ease 0s;
		position: relative;
		display: inline-block;
		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			bottom: 0;
			left: -30px;
			width: 0%;
			height: 1px;
			background: #fff;
			transition: all 0.2s ease 0s;
		}
		@media (min-width:1024px) {
			&:hover {
				&::before {
					width: 100%;
					left: 0;
				}
			}
		}
	}
}

.team {
	padding-top: 150px;
	&__title {
		padding: 0px 0px 20px 0px;
	}

	&__description {
		font-size: 17px;
		line-height: 145%;
		text-align: center;
		margin: 0px auto 40px auto;
		max-width: 690px;
	}

	&__members {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 -15px;
	}
}
.member {
	flex: 0 0 33.333%;
	padding: 15px;
	margin: 0 0 50px 0;
	@media (min-width: 1024px) {
		&:hover {
			.member__mask {
				transform: translate(0, 0);
			}
			.member__name {
				color: #6c7bdf;
			}
			.member__image {
				img {
					transform: scale(1.05);
					filter: grayscale(0);
				}
			}
		}
	}
	@media (max-width: 767px) {
		margin: 0;
	}
	&__body {
		min-width: 270px;
	}

	&__image-wrap {
		transition: all 0.5s ease 0s;
		padding: 10px;
		border: 2px solid transparent;
		border-radius: 50%;
		margin: 0px 0px 20px 0px;
	}

	&__image {
		padding: 0px 0px 100% 0px;
		overflow: hidden;
		position: relative;
		border-radius: 50%;
		max-height: 276px;
		img {
			transition: all 0.8s ease 0s;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			filter: grayscale(100%);
		}
		@media (max-width:767px) {
			img{
				filter: grayscale(0);
			}
		}
	}

	&__mask {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: linear-gradient(180deg, rgba(155, 164, 227, 0) 68.64%, rgba(94, 110, 221, 0.68) 100%);
		transition: all 0.8s ease 0s;
		transform: translate(0, 100%);
	}

	&__name {
		font-weight: bold;
		font-size: 18px;
		line-height: 130%;
		text-align: center;
		margin: 0px 0px 10px 0px;
		transition: all 0.5s ease 0s;
	}

	&__position {
		font-size: 15px;
		line-height: 130%;
		text-align: center;
		color: #999bae;
	}
}

.pop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	place-items: center;
	background: rgba(0, 0, 0, 0.8);
	z-index: 20;
	transition: all 0.3s ease 0s;
	opacity: 0;
	visibility: hidden;
	&.pop_open{
		opacity: 1;
		visibility: visible;
	}
	&__body {
		text-align: center;
		background: #fff;
		border-radius: 20px;
		padding: 30px;
		p {
			font-size: 20px;
			line-height: 120%;
			&:first-child{
				font-size: 26px;
				margin-bottom: 16px;
				font-weight: 700;
			}
		}
	}
}

.mainscreen {
	background: url("../images/left-small-bg.svg") left bottom no-repeat,
		url("../images/left-big-bg.svg") left bottom no-repeat, url("../images/right-small-bg.svg") right top no-repeat,
		url("../images/right-big-bg.svg") right bottom/contain no-repeat, #191b28;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	min-height: 568px;
	perspective: 1000px;
	&__container {
		margin: 0 auto;
		max-width: 1920px;
		padding: 20px;
		transform-style: preserve-3d;
	}

	&__body {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100vh;
		transform-style: preserve-3d;
		&.mozilla {
			transform-style: flat;
			transform: rotate(0) !important;
			flex-direction: column;
		}
		@media (max-width: 767px) {
			transform-style: flat;
			transform: rotate(0) !important;
			flex-direction: column;
		}
	}

	&__logo {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 445px;
		@media (max-width: 767px) {
			max-width: 225px;
		}
	}

	&__text {
		position: absolute;
		bottom: 150px;
		left: 50%;
		transform: translate(-50%, 0);
		color: #fff;
		font-size: 16px;
		text-align: center;
		a {
			color: #fff;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 1px;
				opacity: 1;
				visibility: visible;
				background: #fff;
				transition: all 0.3s ease 0s;
			}
			@media (max-width: 1023px) {
				text-decoration: underline;
				&:before {
					display: none;
				}
			}
			@media (min-width: 1024px) {
				&:hover {
					&:before {
						opacity: 0;
						visibility: hidden;
						right: -30px;
						width: 0;
					}
				}
			}
		}
		@media (max-width: 767px) {
			display: none;
		}
		@media (max-height: 800px) {
			top: 8%;
		}
	}

	&__text-mobile {
		display: none;
		color: #fff;
		font-size: 20px;
		text-align: center;
		margin: 50px 0px 20px 0px;
		a {
			color: #fff;
			text-decoration: underline;
		}
		@media (max-width: 767px) {
			display: none;
		}
		@media (max-height: 700px) {
			bottom: 70px;
		}
		@media (max-width: 767px) {
			display: block;
		}
		@media (max-width: 425px) {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			line-height: 145%;
		}
	}

	&__quarter {
		position: relative;
		perspective: 200px;
		transform-style: preserve-3d;
		.quarter-title {
			position: absolute;
			color: #fff;
			font-weight: 700;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			background: rgba(56, 56, 56, 0.6);
			padding: 5px;
			border-radius: 5px;
			font-size: 16px;
			transition: all 0.3s ease 0s;
			opacity: 0;
			visibility: hidden;
			transform-style: preserve-3d;
			@media (max-width: 767px) {
				opacity: 1;
				visibility: visible;
				min-height: 42px;
				min-width: 76px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				border: 1px solid rgb(153, 153, 153);
				font-size: 13px;
			}
		}
		@media (min-width: 768px) {
			&.mozilla {
				&:hover {
					.quarter-title {
						opacity: 1;
						visibility: visible;
						transform: translate3d(-50%, -40px, 10px);
					}
				}
			}
			&:hover {
				.quarter-title {
					opacity: 1;
					visibility: visible;
					transform: translate3d(-100px, -50px, 50px);
				}
			}
		}
	}

	&__scroll {
		position: fixed;
		bottom: 70px;
		left: 50%;
		width: 40px;
		transition: all 0.3s ease 0s;
		animation: scroll-anim 1s ease-in-out infinite alternate-reverse;
		img {
			max-width: 100%;
			transform: translate(-6px, 0);
		}
		@media (max-height: 700px) {
			display: none;
		}
	}
}
.cross {
	width: 209px;
	img {
		max-width: 100%;
	}
	@media (max-width: 767px) {
		width: 100px;
	}
}
.icon {
	position: absolute;
	transition: all 0.8s ease 0s;
	width: 83px;
	img {
		max-width: 100%;
	}
	@media (max-width: 767px) {
		width: 40px;
	}
}
.green-quarter {
	margin: 0px 25px 25px 0px;
	transition: all 0.3s ease 0s;
	@media (min-width: 768px) {
		&.mozilla {
			&:hover {
				transform: translateZ(0);
				.icon {
					transform: translate(-230px, 50%);
				}
			}
		}
		&:hover {
			transform: translateZ(200px);
			.icon {
				transform: translate(-230px, 50%) rotateY(360deg);
			}
		}
	}
	.icon {
		bottom: 50%;
		left: 180px;
		transform: translate(0%, 50%);
		@media (max-width: 767px) {
			transform: translate(-88px, 50%);
		}
	}
}
.red-quarter {
	transition: all 0.3s ease 0s;
	@media (min-width: 768px) {
		&.mozilla {
			&:hover {
				transform: translateZ(0);
				.icon {
					transform: translate(-50%, -230px) rotateX(0deg);
				}
				.quarter-title {
					transform: translate3d(-50%, -50px, 10px);
				}
			}
		}
		&:hover {
			transform: translateZ(200px);
			.icon {
				transform: translate(-50%, -230px) rotateX(360deg);
			}
		}
	}
	.icon {
		bottom: -30px;
		left: 50%;
		transform: translate(-50%, 0%);
		@media (max-width: 767px) {
			transform: translate(-50%, -22px);
		}
	}
	@media (max-width: 767px) {
		.quarter-title {
			transform: translate(-50%, -33px);
		}
	}
}
.yellow-quarter {
	transition: all 0.3s ease 0s;
	@media (min-width: 768px) {
		&.mozilla {
			&:hover {
				transform: translateZ(0);
				.icon {
					transform: translate(-50%, 230px);
				}
			}
		}
		&:hover {
			transform: translateZ(200px);
			.icon {
				transform: translate(-50%, 230px) rotateX(360deg);
			}
		}
	}
	.icon {
		top: -55px;
		left: 50%;
		transform: translate(-50%, 0%);
		@media (max-width: 767px) {
			transform: translate(-50%, 23px);
		}
	}
}
.blue-quarter {
	transition: all 0.3s ease 0s;
	@media (min-width: 768px) {
		&.mozilla {
			&:hover {
				transform: translateZ(0);
				.icon {
					transform: translate(230px, 50%);
				}
			}
		}
		&:hover {
			transform: translateZ(200px);
			.icon {
				transform: translate(230px, 50%) rotateY(360deg);
			}
		}
	}
	.icon {
		bottom: 50%;
		right: 180px;
		transform: translate(0%, 50%);
		@media (max-width: 767px) {
			transform: translate(87px, 50%);
		}
	}
}
.navigation {
	position: fixed;
	z-index: 20;
	right: 70px;
	bottom: 100px;
	@media (max-width: 767px) {
		right: 20px;
	}

	&__list {
		position: relative;
	}

	&__phone {
		width: 40px;
		cursor: pointer;
		transition: all 0.3s ease 0s;
		position: fixed;
		bottom: 30px;
		right: 78px;
		&>img{
			position: relative;
			z-index: 1;
			top: -4px;
			opacity: 0.8;
			@media (min-width:1024px) {
				&:hover{
					opacity: 1;
				}
			}
		}
		img {
			width: 100%;
			transition: all 0.3s ease 0s;
			@media (min-width: 1024px) {
				&:hover {
					transform: scale(1.1);
				}
			}
		}
		&.hide {
			transform: translate(400px, 0);
			opacity: 0;
			visibility: hidden;
		}
		&._open {
			.navigation__socials {
				li {
					position: relative;
					z-index: 0;
					opacity: 0.5;
					visibility: visible;
					&:nth-of-type(1) {
						transform: translate(-60px, 0px);
					}
					&:nth-of-type(2) {
						transform: translate(-110px, 0px);
					}
					&:nth-of-type(3) {
						transform: translate(-160px, 0px);
					}
					&:nth-of-type(4) {
						transform: translate(-210px, 0px);
					}
					@media (min-width:1024px) {
						&:hover{
							opacity: 1;
						}
					}
					@media (max-width:1023px) {
						opacity: 1;
					}
				}
			}
		}
		@media (max-width:767px) {
			right: 27px;
		}
	}

	&__socials {
		position: relative;
		transition: all 0.3s ease 0s;
		li {
			transition: all 0.3s ease 0s;
			opacity: 0;
			visibility: hidden;
		}
	}

	&__social {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -45px);
		height: 40px;
		width: 40px;
		img {
			display: block;
			max-width: 100%;
		}
	}

	&__lang {
		cursor: pointer;
		position: relative;
		transition: all 0.3s ease 0s;
		&.hide {
			transform: translate(100px, 0);
			opacity: 0;
			visibility: hidden;
		}
	}

	&__globe {
		position: relative;
		z-index: 1;
		width: 50px;
		transition: all 0.3s ease 0s;
		img {
			width: 100%;
		}
		@media (min-width: 1024px) {
			&:hover {
				transform: scale(1.1);
			}
		}
	}

	&__flag {
		position: absolute;
		top: 2px;
		left: 2px;
		z-index: 0;
		width: 50px;
		height: 50px;
		opacity: 0;
		visibility: hidden;
		transition: all 0.2s ease 0s;
		display: block;
		border-radius: 50%;
		background-size: cover;		
		background-position: center;
		background-repeat: no-repeat;
		background-clip: content-box;
		padding: 5px;
		&.chosen {
			padding: 5px;
			border: 2px solid #9ba4e3;
			border-radius: 50%;
		}
	}

	&__ru {
		transform: translate(0, 0);
		&.active {
			transform: translate(0, -70px);
			opacity: 1;
			visibility: visible;
		}
	}

	&__uk {
		transform: translate(0, 0);
		&.active {
			transform: translate(-70px, 0px);
			opacity: 1;
			visibility: visible;
		}
	}

	&__tr {
		transform: translate(0, 0);
		&.active {
			transform: translate(-60px, -60px);
			opacity: 1;
			visibility: visible;
		}
	}

	&__menu {
		position: relative;
		top: 0;
		left: 20px;
		transition: all 0.3s ease 0s;
		transform: translate(100px, 0);
		opacity: 0;
		visibility: hidden;
		&.show {
			transform: translate(0, 0);
			opacity: 1;
			visibility: visible;
		}
	}

	&__burger {
		width: 44px;
		height: 44px;
		position: absolute;
		bottom: -5px;
		left: -30px;
		cursor: pointer;
		transition: all 0.3s ease 0s;
		z-index: 5;
		&:before,
		&:after {
			content: "";
			position: absolute;
			display: inline-block;
			background: #898a91;
			width: 17px;
			height: 4px;
			border-radius: 5px;
			transition: all 0.2s ease 0s;
		}
		&:before {
			bottom: 8px;
			left: 5px;
		}
		&:after {
			top: 8px;
			left: 16px;
		}
		span {
			transition: all 0.2s ease 0s;
			position: absolute;
			display: inline-block;
			background: #898a91;
			width: 17px;
			height: 4px;
			border-radius: 5px;
			top: 20px;
			left: 10px;
		}
		@media (min-width: 1024px) {
			&:hover {
				&:before {
					width: 100%;
					left: 0;
				}
				&:after {
					width: 100%;
					left: 0;
				}
				span {
					width: 100%;
					left: 0;
				}
			}
		}
		@media (max-width: 1023px) {
			&:before {
				width: 100%;
				left: 0;
			}
			&:after {
				width: 100%;
				left: 0;
			}
			span {
				width: 100%;
				left: 0;
			}
		}
		&.open {
			span {
				opacity: 0;
				visibility: hidden;
				width: 0;
			}
			&:before {
				width: 100%;
				left: 0;
				transform: rotate(-45deg) translate(7px, -7px);
				background: #898a91;
			}
			&:after {
				width: 100%;
				left: 0;
				transform: rotate(45deg) translate(10px, 10px);
				background: #898a91;
			}
			@media (min-width: 1024px) {
				&:hover {
					&:before,
					&:after {
						background: #898a91;
					}
				}
			}
		}
		@media (max-width: 767px) {
			left: -60px;
		}
		&.full {
			&:before {
				width: 100%;
				left: 0;
			}
			&:after {
				width: 100%;
				left: 0;
			}
			span {
				width: 100%;
				left: 0;
			}
		}
	}

	&__blocks {
		position: absolute;
		top: -37px;
		left: -5px;
		width: 43px;
		height: 43px;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease 0s;
		a {
			transition: all 0.2s ease 0s;
			display: inline-block;
			width: 60px;
			height: 60px;
			padding: 2px;
			border: 1px solid transparent;
			border-radius: 50%;
			position: absolute;
			top: 0;
			left: 0;
			img {
				max-width: 100%;
			}
			&:hover {
				border: 1px solid #9ba4e3;
			}
		}
		&.extend {
			opacity: 1;
			visibility: visible;
		}
		@media (max-width: 767px) {
			left: -35px;
		}
	}

	&__green {
		&.extend {
			transform: translate(-90px, 24px);
		}
	}

	&__yellow {
		&.extend {
			transform: translate(-90px, -40px);
		}
	}

	&__blue {
		&.extend {
			transform: translate(-35px, -75px);
		}
	}

	&__red {
		&.extend {
			transform: translate(-35px, 56px);
		}
	}
}
@keyframes scroll-anim {
	0% {
		transform: translate(-50%, -10px);
	}
	100% {
		transform: translate(-50%, 10px);
	}
}

.works {
	transition: all 0.8s ease 0s;
	position: relative;
	&__title {
		transition: all 0.3s ease 0s;
	}

	&__cards {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 -15px 60px -15px;
	}

	&__button-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__button {
		background: #6978df;
		&:hover {
			border: 1px solid #6978df;
		}
	}

	&__spinner{
		top: -5px;
	}
}
.card {
	flex: 0 0 33.333%;
	padding:15px;
	transition: all 0.8s ease 0s;
	cursor: pointer;

	&.card--open{
		flex: 1 1 auto;
	}

	&__body {
		border-radius: 20px;
		overflow: hidden;
		width: 100%;
		min-width: 270px;
		position: relative;
		&:hover {
			.card__hover {
				transform: translate(0, 0);
			}
		}
	}

	&__image {
		padding: 0px 0px 100% 0px;
		overflow: hidden;
		position: relative;
		transition: all 0.8s ease 0s;
		img {
			transition: all 0.3s ease 0s;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__hover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(105, 120, 223, 0.74), rgba(105, 120, 223, 0.74));
		color: #fff;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		transition: all 0.4s ease 0s;
		transform: translate(0, 100%);
		@media (max-width: 767px) {
			transform: translate(0, 0);
		}
	}

	&__heading {
		font-weight: bold;
		font-size: 18px;
		line-height: 130%;
		margin: 0px 0px 20px 0px;
	}

	&__type {
		font-size: 14px;
		line-height: 15%;
		margin: 0px 0px 30px 0px;
	}
}

.case {
	&__href {
		margin-bottom: 20px;
	}
	display: none;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
	min-height: 100vh;
	top: 0;
	left: 0;
	width: 100%;
	&__bg{
		background: rgba(255, 255, 255, 0.9);
		padding: 50px 0;
		min-height: 100vh;
		cursor: default;
	}
	&__close {
		position: fixed;
		right: 50px;
		top: 100px;
		height: 60px;
		width: 38px;
		z-index: 1;
		transition: all 0.3s ease 0s;
		cursor: pointer;
		.case__close-bar{
			display: none;
		}
		&:before,
		&:after {
			content: "";
			position: absolute;
			height: 5px;
			width: 30px;
			display: inline-block;
			background: #898a91;
			border-radius: 3px;
		}
		&:before {
			transform: rotate(45deg) translate(3px, 22px);
		}
		&:after {
			transform: rotate(-45deg) translate(-22px, 3px);
		}
		@media (min-width: 1024px) {
			&:hover {
				transform: scale(1.15);
			}
		}
		@media (max-width: 767px) {
			right: 0;
			bottom: 0;
			top: auto;
			width: 100%;
			height: 50px;
			&:before,
			&:after,
			span{
				display: none;
			}
			.case__close-bar{
				display: block;
				color: #fff;
				font-weight: 700;
				background: #6978df;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 20px;
			}
		}
	}
	&__wrap {
		background: rgba(255, 255, 255, 0.8);
		min-height: 500px;
		transition: all 0.8s ease 0s;
		color: #212121;
		text-align: center;
		padding: 30px;
		position: relative;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}
	&__subtitle {
		margin: 0px 0px 10px 0px;
		transition: all 0.8s ease 0s;
	}
	&__type {
		margin: 0px 0px 10px 0px;
		transition: all 0.8s ease 0s;
	}
	img {
		max-width: 100%;
		float: left;
		margin: 0 0px 10px 0;
	}
	p {
		margin: 0px 0px 10px 0px;
		font-size: 16px;
		line-height: 140%;
	}
}

#ajax,#ajax_count {
	display: none;
}

// Общее

@import "./includes/normalize.scss";
@import "./includes/font.scss";

// Общие стили
body {
	font-family: "Gilroy", Arial, Helvetica, sans-serif;
	overflow-x: hidden;
	color: #212121;
}
.main {
	min-height: 100%;
	width: 100%;
	position: relative;
}
.gradient-wrap {
	background: linear-gradient(180deg, #ffffff 0%, #e7edfa 50%, #ffffff 100%);
}
.container {
	max-width: 1000px;
	padding: 0 20px;
	margin: 0 auto;
}
.title {
	font-weight: 700;
	font-size: 36px;
	line-height: 130%;
	text-align: center;
	padding: 0px 0px 50px 0px;
	position: relative;
	span {
		display: inline-block;
	}
	&:before {
		content: "";
		position: absolute;
		top: -100px;
		left: 50%;
		height: 66px;
		width: 2px;
		background: #9ba4e3;
	}
	@media (max-width: 425px) {
		font-size: 30px;
	}
}
.button {
	font-weight: bold;
	font-size: 12px;
	line-height: 130%;
	text-align: center;
	letter-spacing: 0.23em;
	text-transform: uppercase;
	color: #ffffff;
	padding: 20px 45px;
	border: 1px solid #ffffff;
	border-radius: 90px;
	transition: all 0.3s ease 0s;
	span{
		transition: all 0.3s ease 0s;
	}
	&:hover {
		background: #fff;
		color: #212121;
	}
}
.subtitle {
	font-size: 26px;
	font-weight: 700;
	line-height: 145%;
	margin: 0px 0px 40px 0px;
	@media (max-width: 425px) {
		text-align: center;
		margin: 0px 0px 30px 0px;
		font-size: 22px;
		line-height: 130%;
	}
}
.spinner-wrap {
	position: relative;
	transition: all 0.3s ease 0s;
	&._sending{
		.spinner{
			opacity: 1;
			visibility: visible;
		}
		span{
			opacity: 0;
			visibility: hidden;
		}
		#btn-finish{
			@media (max-width:425px) {
				background: #6978df;
			}
		}
	}
}
.spinner {
	position: absolute;
	top: 14px;
	left: 50%;
	width: 40px;
	height: 29px;
	text-align: center;
	font-size: 10px;
	transform: translate(-50%,0);
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease 0s;
	@media (max-width:425px) {
		top: 7px;
	}
}

.spinner > div {
	background-color: #fff;
	height: 100%;
	width: 3px;
	display: inline-block;

	-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
	animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.spinner .rect3 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

.spinner .rect4 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.spinner .rect5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1);
	}
}

@keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}

// Блоки

@import "./components/mainscreen.scss";
@import "./components/product.scss";
@import "./components/works.scss";
//@import "./components/quiz.scss";
@import "./components/team.scss";
@import "./components/contacts.scss";
@import "./components/pop.scss";



.product {
	padding: 150px 0px 150px 0px;
	&__list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 -15px;
	}
}
.item {
	flex: 0 0 33.333%;
	padding: 15px;
	min-width: 280px;
	@media (max-width:425px) {
		flex: 1 1 auto;
		padding: 20px;
	}
	&__inner{
		perspective: 1000px;
		transform-style: preserve-3d;
		transition: all 0.8s ease 0s;
		position: relative;
		min-height: 150px;
		&:hover{
			.item__front{
				transform: rotateY(-180deg);
			}
			.item__back{
				transform: rotateY(0deg);
			}
		}
	}
	&__front {
		box-shadow: 0px 10px 60px rgba(47, 52, 67, 0.1);
		padding: 15px;
		border-radius: 20px;
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		transition: all 0.5s ease 0s;
		backface-visibility: hidden;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 2;
		overflow: hidden;
	}

	&__image {
		width: 60px;
		margin: 0 0 20px 0;
		img {
			max-width: 100%;
		}
	}

	&__back {
		padding: 15px;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transition: all 0.5s ease 0s;
		transform: rotateY(180deg);
		background: linear-gradient(270deg, #9BA4E3 -37.46%, #5E6EDD 123.23%);
		border-radius: 20px;
		color: #FFFFFF;
		text-align: center;
		backface-visibility: hidden;
		overflow: hidden;
	}

	&__back-heading {
		font-weight: bold;
		font-size: 18px;
		line-height: 130%;
		margin: 0px 0px 10px 0px;
	}

	&__description {
		font-weight: normal;
		font-size: 14px;
		line-height: 135%;
	}
}

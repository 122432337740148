@font-face {
	font-family: "Gilroy";
	src: url("../fonts/gilroy-regular.eot");
	src: url("../fonts/gilroy-regular.eot?#iefix") format("embedded-opentype"), 
		url("../fonts/gilroy-regular.woff2") format("woff2"),
		url("../fonts/gilroy-regular.woff") format("woff"), 
		url("../fonts/gilroy-regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Gilroy";
	src: url("../fonts/gilroy-bold.eot");
	src: url("../fonts/gilroy-bold.eot?#iefix") format("embedded-opentype"), 
	url("../fonts/gilroy-bold.woff2") format("woff2"),
		url("../fonts/gilroy-bold.woff") format("woff"), 
		url("../fonts/gilroy-bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}